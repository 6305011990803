import React, { useState, useEffect, useRef } from 'react'
import { useStateMachine } from 'little-state-machine'
import { navigate, Link } from 'gatsby'
import axios from 'axios'
import queryString from 'query-string'

import { updateDetails } from './updateDetails'
import Spinner from '../elements/Spinner'
import HubspotForms from '../Hubspot/HubspotForms'
import Emoji from '../helpers/Emoji'
import { isBrowser } from '../helpers/helpers'

import * as SignUpStyles from './signup.module.scss'
import './signupForm.scss'

const EmailForm = ({ location }) => {

  const [isLoading, setIsLoading] = useState(false)

  const { actions, state } = useStateMachine({ updateDetails })

  const emailFromXero = useRef()

  // Initial component load hook, if we've arrived here after returning from a
  // redirect through Xero we exchange the code recieved for user details to
  // prepopulate the email box on the form.
  useEffect(() => {

    const url = new URL(window.location)
    const {
      xero: isXero = false,
      complete: isCompleted = false,
      code,
    } = queryString.parse(location.search)

    if (isXero && isCompleted) {
      // This is a return back from Xero after redirecting the user there upon
      // loading our sign up form from the Xero App Store. We exchange the code
      // they'll have included in the url for their details and auto-fill the
      // sign up form.
      setIsLoading(true)
      axios
        .post(`${process.env.GATSBY_APIURL}/r/XeroOpenId`, {
          code,
          origin: window.localStorage.getItem('@@loaded/xeroOAuthOrigin')
        })
        .then(response => {
          setIsLoading(false)
          const userEmail = response?.data?.email
          const firstName = response?.data?.given_name
          const surname = response?.data?.family_name
          if (userEmail) {
            actions.updateDetails({email: userEmail, fullName: `${firstName} ${surname}`})
            emailFromXero.current = userEmail

            // We may be running after the HubSpot form has loaded, in this case
            // we need to update the field in-situ instead of the HubSpot form
            // ready callback populating it after we've set it in the form state.
            const emailField = getHubSpotEmailField()
            if (emailField) {
              emailField.value = userEmail
              emailField.dispatchEvent(new Event('input', { bubbles: true }))
            }
          }
        })
    }

  }, [])

  // Return a DOM reference to the email field of the embedded HubSpot form.
  const getHubSpotEmailField = () => {
    const form = document.querySelector(`#hsForm_${process.env.GATSBY_HUBSPOT_EMAIL_SIGNUP_FORM_ID}`)
    return form ? form.querySelector(`#email-${process.env.GATSBY_HUBSPOT_EMAIL_SIGNUP_FORM_ID}`) : null
  }

  // Attach an event listener once when the component is rendered to cater for
  // the HubSpot form API and attaching to it's lifecycle events. We can't do
  // this directly via the options properties onFormReady etc because they
  // don't have the expected jquery dependnecy in place. This event listener,
  // while ugly, avoids us having to embed another version of jquery.
  const email = useRef()
  const hsFormSubmitting = useRef(false)
	useEffect(() => {
    if (isBrowser()) { 

      const handleHubspotFormEvents = event => {
        if (!event.data.type === 'hsFormCallback') return

        let emailField
        switch (event.data.eventName) {
          case 'onFormReady':
            const emailToUse = state?.signUpDetails?.email || emailFromXero.current

            if (emailToUse) {
              emailField = getHubSpotEmailField()
              emailField.value = emailToUse
              emailField.dispatchEvent(new Event('input', { bubbles: true }))
            }

            break
          case 'onFormSubmit':
            emailField = getHubSpotEmailField()
            email.current = emailField.value
            hsFormSubmitting.current = true
            break
          case 'onFormSubmitted':
            actions.updateDetails({ email: email.current })
            navigate('/sign-up/welcome')
            break
        }
      }

      window.addEventListener('message', handleHubspotFormEvents)

      // it's important we remove the listeners, else when navigating in the
      // invite screens the sign-up event listeners above try and fire when
      // we've already rendered something different - the js exception causes
      // the rendering to fail on the invite screen
      return () => {
        window.removeEventListener('message', handleHubspotFormEvents)
      }
		}
	},[])

  return (
    <div className='component email-form'>
      <h1>Sign Up</h1>
      <div className={SignUpStyles.subText}>Get yourself a free 30 day trial! <Emoji symbol='👊' label='fist bump' /> </div>

      { isLoading ?
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '128px', marginBottom: '128px'}}>
					<Spinner dark />
				</div>
			:
				<div>
          {/* The visibility style here is a workaround to a render quirk with the HubSpot form.
              When it's submitted the jquery code hides the form, but then it gets re-rendered
              by React and ends up visible for a brief flash before we transition to the second
              stage 'welcome' form. We hide the form when we've detected a form submission via
              CSS here. */}
          <div style={{ visibility: hsFormSubmitting.current === true ? 'hidden' : 'visible' }}>
            <HubspotForms
              formID={`${process.env.GATSBY_HUBSPOT_EMAIL_SIGNUP_FORM_ID}`}
              blockID={`form-${process.env.GATSBY_HUBSPOT_EMAIL_SIGNUP_FORM_ID}`}
              formOptions={{
                locale: 'en',
                translations: {
                  en: {
                    required: 'A valid email address is required'
                  }
                },
              }}
              formHeight='112px'
            />
          </div>
          <div className='loginLink' style={{marginTop: '32px', marginBottom: '64px', textAlign: 'center'}}>Already got an account? You can <Link to='/login'>login here</Link>.</div>
				</div>
      }

      <div className={SignUpStyles.disclaimer}>
        By creating a Loaded account I agree to the <Link to='/end-user-licence-agreement'>End User Licence Agreement</Link> and <Link to='/privacy-policy'>Privacy Policy.</Link>
      </div>
    </div>
  )
}

export default EmailForm
